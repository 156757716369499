<template>
  <svg :style="{ fontSize: size + 'px' }" class="icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 30
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName() {
      return '#icon-' + this.icon.replace('#icon-', '')
    }
  }
}
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
